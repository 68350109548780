<template>
  <div class="page">
    <div class="segment">
      <div class="content narrow">
        <div class="post column">
          <div class="post-banner">
            <img src="@/assets/pagebanner-shop.jpg">
          </div>
          <div class="post-content-wrapper">
            <pre class="post-content">
              <b>All tickets to Berget 21 will be released HERE on Sunday the 17th of November 2024 20:00 CET (GMT+01).</b> The tickets will be sold at a 30€ discount the first night to the price of 159€. After the first night the ticket price will be raised to 189€, and from March it will be 220€. We are doing this to reward those who buy their tickets early which help us plan and make a better Berget for all.
            </pre>
            <pre v-if="0">
                The tickets for Berget 21 are now released!

                <a href="https://berget-portal.com/" class="buy-tickets-here">BUY TICKETS HERE!</a>
              </pre>
            <pre class="post-content">
              <b>INCLUDED IN TICKET</b>
              - Entrance to game area
              - Sleeping place and camp according to booking
              - Drinking water
              - Access to cold showers
              - Access to toilets
              - Access to Wifi
              - Berget T-shirt
              - 230V sockets for battery charge in Camp and Safe-zone

              NOTE! To attend to Berget games you need to be able to make yourself understood and understand basic English. Please try to improve your basic English before going to the game. If you are unable to learn basic English you'll need to play close to other teammates who can speak English.
            </pre>
          </div>

        </div>

        <div class="post column">
          <div class="post-content-wrapper">
            <div class="post-header">PRICE FOR OPTIONAL EXTRAS</div>
            <pre class="post-content">
              <b>Food</b>
              Food Ticket, (Lunch & Dinner, 5 meals) = 59 EURO

              <b>Storage</b>
              Renting a storage Box with padlock at gamesite = 35 EURO

              <b>BAVS</b>
              BAVS Hit receiver for vehicle, Rental Only = 75 EURO
              BAVS 40mm Grenade, Rental Only = 70 EURO
              BAVS AT-4 anti vehicle weapon , Rental Only = 85 EURO
              BAVS Heavy weapon unit TOW or TANK weapon, Rental Only = 90 EURO
              BAAS Heavy Stinger anti-air gun, Rental Only = 120 EURO

              <b>Transport</b>
              Transport roundtrip Closest Bus/Train station - Gamearea = 35 EURO
              Transport roundtrip Sundsvall Airport - Gamearea = 100 EURO
              Transport roundtrip Sundsvall Train station - Gamearea = 115 EURO

              <b>Armbands & Patches</b>
              Team Armband = 15 EURO
              Medic Armband = 15 EURO
              Engineer Armband = 15 EURO
              Berget velcro patch = 9 EURO
              Squad leader velcro patch = 9 EURO
              Deathrag = 10 EURO

              <b>Maps</b>
              A3 Game area premium color map (water proof, rip stop) = 10 EURO

              <b>BB's and Gas</b>
              Bioval BBs 0,25g 4000pc = 16 EURO
              Asg gas 570ml = 14 EURO

              <b>On-site shop</b>
              We have a large Airsoft shop on site that sells all the accessories you need to participate.
              Guns, magazines, goggles, BBs, gas, HPA and much more.

              <img style="width: 100%" src="/img/shop.jpg">
            </pre>

          </div>

        </div>

        <div class="post column">
          <div class="post-content-wrapper">
            <div class="post-header">Participant agreement</div>
            <pre class="post-content">

              To participate in a Berget airsoft event you must accept the following:

              1.1
              You abide by Swedish law.
              You will not be under the influence of alcohol or any illegal drugs during the game.
              You are eighteen years of age or older.
              You learn all the rules posted at the Berget-events.com webpage.
              You will abide by these rules.
              You will follow directions of the crew and the functionaries.
              You will not spread rumors and disinformation during the event.
              You will bring valid identification, like a passport or a driver's license with a picture.

              Note! If you break any of the rules above your ticket will be confiscated and you will be asked to leave the game-area.

              1.2
              Berget-Events cannot be held accountable for lost or broken materials during the event. Berget-events will not be accountable for theft, personal injury or disease that occurs during or after the event. Any crimes committed in the game-area should be brought to Swedish police by the participant. You attend to Berget games at your own risk.

              1.3
              As a participant you must have your insurance in order and bring the relevant papers in case you need to seek medical attention at a Swedish hospital. If you need to visit a hospital or dentist in a non-emergency situation, Berget-events cannot arrange free transportation.

              1.4
              Berget-Events do not give refunds on tickets. If you due to unforeseen circumstances cannot attend the event, all sales will be done by the owner of the ticket. This means that the buyer of the tickets accepts that the ticket won't be refunded and fully accepts that the participant is responsible for selling the ticket second-hand and will not claim any refund from Berget-events. Should a Berget event be cancelled all ticket holders will be refunded after a preparation fee has been deducted. Therefore the fee can be lower than the initial price. If an event is postponed all current ticket owners will retain their tickets for the next event without any claims of refund.

              1.5
              The ticket you buy is an e-ticket which means that it is digital, keep your ticket-ID written down and keep it safe. Use it only in communication with us.

              1.6
              Berget-events cannot promise any certain amount of action or fun during our events, we only provide the platform, story and logistics for the game. It is on your own responsibility to make the game fun for yourself.

              1.7
              After arriving to the game site, Berget-events do not provide any transport service on the game-area. You will have to be prepared to walk to your base, camp, safe-zone, respawn during game.

              1.8
              If you have lost equipment found by Berget-events. Berget-events cannot be held responsible for your equipment. Check the lost and found box before leaving the game-area. All costs regarding shipment of your lost equipment must be paid by you in advance.

              1.9
              Berget games are not MILSIM, but they are airsoft games with some elements of milsim and LARP.

              2.0
              Berget-provides a sleeping place (200 x 60cm) in a tent or shelter. Berget-events do not provide any sleeping bag or mattress. It is prohibited to bring larger mattresses/beds than 200 x 60 cm to tents supplied by Berget-Events.

              2.1
              Berget-events reserve the right to raise / lower the prices for unpaid tickets and services at any time if the EURO currency weakens against the Swedish krona (SEK) with more the 5 %. The current prices are based on 1 EURO = 11 SEK. Tickets already bought by players won't be affected by this.

              2.2
              Berget-events reserve the right to use any photo- and video material taken by crew or players during the events for commercial/marketing purposes. By agreeing to this you acknowledge that you might be photographed or filmed at some point during the event.

              2.3
              Berget-events reserves the right to hold a participant financially responsible for lost or damaged property rented / borrowed by Berget-events. Berget owned items like AT-4, BAVS hit receiver, officer patches or Radios should be returned before leaving the event. If payment are not made on-site, an international debt collection company will handle the matter in the participants country.

              2.4
              Only Bio degradable, non-toxic BBs can be used during the event. Players using non BIO BB's are committing an environmental crime in Sweden and can be held responsible. We recommend all players to use BIO bb's with 18 months outdoor degradation-time or less.

              2.5
              You will bring protective goggles, ID, an orange death-rag and booking number for approval at Check-In.

              2.6
              To play with a HPA-gun at Berget Events, you as a player are responsible to offer a way to restrict the air-pressure of the gun.

              2.7
              As a participant in the Berget-game you are not allowed to sell products or services at the game area without prior consent of Berget Crew. Please contact mattias@berget-events.com

              2.8
              You are not allowed to fly an UAV in connection to the game area without prior consent of Berget Crew. Please contact info@berget-events.com

              2.9
              You are not allowed to market companies products or services on the game area, for instance on clothing, cars etc.&nbsp;without prior consent of Berget Crew. Please contact mattias@berget-events.com
            </pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Shop'
}
</script>

<style scoped>
.buy-tickets-here { font-weight: 800; font-size: 3em; color: #E64020 }
</style>
